import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './MachinesDetails.css';
import { DataService } from '../services/DataService';
import Loading from './Loading';
import ProductNotFound from './ProductNotFound';

const MachinesDetails = () => {
  const { id } = useParams();
  const [tool, setTool] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const powertoolsData = DataService.getPowertoolsAccessoriesData();
    const foundTool = powertoolsData
      .find(category => category.id === 'machines')?.items
      .find(item => item.uuid === id);

    // console.log('foundTool', foundTool);

    setTimeout(() => {  // Optional: Remove this if you don't want artificial delay
      setIsLoading(false);
      if (foundTool) {
        setTool(foundTool);
      }
    }, 1000);
  }, [id]);

  if (isLoading) {
    return (
      <Loading />
    );
  }

  if (!tool) {
    return (
      <ProductNotFound />
    );
  }

  return (
    <div className="pt-powertools-details">
      <div className="pt-powertools-banner">
        <img
          src="/images/details/machines-banner.jpg"
          alt="Power Tools Category Banner"
          className="pt-powertools-banner-image"
        />
        <div className="pt-powertools-banner-overlay">
          <h2 className="pt-powertools-details-title">{tool.id}</h2>
        </div>
      </div>

      <div className="pt-powertools-details-container">
        <div className="pt-powertools-details-card">
          <div className="pt-details-section">
            <h3 className="pt-section-title">Product Details</h3>
            <div className="pt-details-grid">
              <div className="pt-detail-item">
                <span className="pt-detail-label">Model</span>
                <span className="pt-detail-value">{tool.id}</span>
              </div>
              <div className="pt-detail-item pt-description">
                <span className="pt-detail-label">Description</span>
                <span className="pt-detail-value">{tool.description}</span>
              </div>
              {tool.url && (
                <div className="pt-detail-item pt-product-link-container">
                  <a
                    href={tool.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pt-product-link"
                  >
                    View Product
                    <span className="pt-link-arrow">→</span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="machines-details">
    //   <h2 className="machines-details-title">Machine</h2>

    //   <div className="machines-details-container">
    //   <div className="machines-details-group">
    //       <h3>Model</h3>
    //       <p>{tool.id}</p>
    //     </div>

    //     <div className="machines-details-group">
    //       <h3>Description</h3>
    //       <p>{tool.description}</p>
    //     </div>
    //   </div>
    // </div>
  );
};

export default MachinesDetails;