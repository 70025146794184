// src/pages/DieselCenter/DieselCenter.js
import './DieselCenter.css';
import DieselCenterItems from './DieselCenterItems';

// src/pages/DieselCenter/DieselCenter.js
const DieselCenter = () => {

  const items = {
    'service': {
      title: 'Services',
      items: [
        {
          title: 'Injector',
          description: 'Ensure peak performance with our comprehensive injector services:',
          image: 'images/diesel-center/services/dc-injector.png',
          subcategories: [
            { id: 'c1', title: 'Cleaning', name: 'Meticulous removal of dirt and deposits for optimal functionality.' },
            { id: 'c2', title: 'Overhaul and repair', name: 'Restore your injector to like-new condition with expert repairs.' },
            { id: 'c3', title: 'Internal Polishing', name: 'Achieve smooth operations with precision internal refinements.' },
            { id: 'c4', title: 'Testing', name: 'Rigorous quality checks to ensure reliability and performance.' },
          ]
        },
        {
          title: 'Pump',
          description: 'Maximize efficiency with our specialized pump solutions:',
          image: 'images/diesel-center/services/dc-pump.png',
          subcategories: [
            { id: 'c1', title: 'Cleaning', name: 'Designed to minimize maintenance needs' },
            { id: 'c2', title: 'Overhaul and repair', name: 'Restore your pump to like-new condition with expert repairs.' },
            { id: 'c3', title: 'Internal Polishing', name: 'Achieve smooth operations with precision internal refinements.' },
            { id: 'c4', title: 'Testing', name: 'Rigorous quality checks to ensure reliability and performance.' },
          ]
        },
      ]
    },
    'spares': {
      title: 'Spares',
      items: [
        {
          title: 'Nozzles',
          description: 'High-quality fuel injection nozzles designed for optimal fuel atomization and efficient combustion. Available for various diesel engine makes and models, ensuring precise fuel delivery and improved engine performance.',
          image: 'images/diesel-center/spares/nozzles.png'
        },
        {
          title: 'Plungers',
          description: 'Premium-grade diesel pump plungers engineered for durability and precision. These vital components ensure accurate fuel metering and consistent pressure delivery, maintaining optimal engine performance and fuel efficiency. Available for multiple pump types and specifications.',
          image: 'images/diesel-center/spares/plungers.png'
        },
        {
          title: 'Pumps',
          description: 'High-performance diesel fuel injection pumps built to deliver reliable and precise fuel distribution. Our range includes both mechanical and electronic pumps, designed for various applications from light commercial to heavy-duty industrial engines. Each pump undergoes rigorous testing to ensure maximum efficiency and longevity.',
          image: 'images/diesel-center/spares/pumps.png'
        }
      ]
    }
  }


  return (
    <div className='dc-wrapper'>
      <div className="dc-container">
        <div className='dc-title'>
          <h1>DieselCenter</h1>
        </div>
      </div>
      <div className="dropdown-container">
        <DieselCenterItems key={items.service.title} items={items.service} />
        <DieselCenterItems key={items.spares.title} items={items.spares} />
      </div>
    </div>
  );
}

export default DieselCenter;