// src/components/ProductCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import './PowertoolsCard.css'; // Assuming you want to use the same styles

const PowertoolsCard = ({ item }) => {
  return (
    <div className="pc-card">
      <div className="pc-card-content">
        <div className="pc-card-header">
          <h3 className="pc-part-number">{item.description}</h3>
          {/* <p className="pc-bosch-number">Category: {item.category}</p> */}
        </div>
        
        <div className="pc-card-details">
          <div className="pc-detail-row">
            <span className="pc-label">Model:</span>
            <span className="pc-value">{item.model || 'N/A'}</span>
          </div>

          {/* <div className="pc-detail-row">
            <span className="pc-label">Category:</span>
            <span className="pc-value">{item.category || 'N/A'}</span>
          </div> */}

          {/* <div className="pc-detail-row">
            <span className="pc-label">Details:</span>
            <span className="pc-value">{item.details || 'N/A'}</span>
          </div> */}
          
          {/* <div className="pc-detail-row">
            <span className="pc-label">EAN1:</span>
            <span className="pc-value">{item.ean1 || 'N/A'}</span>
          </div>

          <div className="pc-detail-row">
            <span className="pc-label">EAN2:</span>
            <span className="pc-value">{item.ean2 || 'N/A'}</span>
          </div> */}
        </div>
      </div>

      <div className="pc-button-container">
        <Link to={`/powertools/${item.model}`} className="pc-button">
          View Details
        </Link>
        {item.url && (
          <Link to={item.url} className="pc-button" target="_blank" rel="noopener noreferrer">
            View Product
          </Link>
        )}
      </div>

      {/* {item.url && (
        <Link to={item.url} className="pc-read-more" target="_blank" rel="noopener noreferrer">
          View Product
        </Link>
      )} */}
    </div>
  );
};

export default PowertoolsCard;